export class AppConfig {
    // ######### LOCAL ##########
    // url: string = 'http://192.168.0.18/ak_credit/api/public/';
    // ######### PROD  ##########
    //url: string = 'http://api-trijaem.test/api/';
    // url: string = 'https://api.apilaravel.ml/api/';
   //url: string = 'http://127.0.0.1:8000/api/';
   //url : string ="https://suap.trijaem.gob.mx/api/public/api/";
    //url: string = "http://notificaciones.test/api/";
    //url: string = 'http://www.galactisworld.ml/api/';
        url: string = 'https://sapback.bitgob.com/api/';
}