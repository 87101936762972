import { Component } from '@angular/core';

import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { adminMenu, streamMenu, rhMenu, almacenMenu, auxMenu, juntaMenu, rmMenu,SPMenu,rfMenu } from "../../providers/data/menu/menu-data";

import { locale as english } from './i18n/en';
import { locale as turkish } from './i18n/tr';
import { fuseAnimations } from '@fuse/animations';
import { AppConfigService } from 'app/providers/data/app-config.service';
import { Router } from '@angular/router';

export interface Tile {
    color: string;
    cols: number;
    rows: number;
    text: string;
  }
@Component({
    selector   : 'sample',
    templateUrl: './sample.component.html',
    styleUrls  : ['./sample.component.scss'],
    animations : fuseAnimations
})
export class SampleComponent
{
    boards: any[] = [];

    currentUser:any;
    typeEmployee:any;
    dialogRef: any;
    /**
     * Constructor
     *
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _settings: AppConfigService,
        private _router: Router,
    )
    {
        this._fuseTranslationLoaderService.loadTranslations(english, turkish);
        this.currentUser = this._settings.getCurrentUser();
        this.typeEmployee  = this.currentUser ? this.currentUser.tipo : undefined;
    }

    ngOnInit(){
        if(this.typeEmployee !== undefined) {
            if(this.typeEmployee === 'empleado') {
                this.typeEmployee = this._settings.getTypeEmployee();
            }
            this.boards = this.getBoard(this.typeEmployee);
        }else {
            console.log('not user')
            this._router.navigate(['/']);
        }
    }

    getBoard(typeUser){
        let menu: any = [];
        console.log(typeUser);

        switch (typeUser) {
            case 'admin':
                menu = (streamMenu);
                break;
            case 'rh':
                menu = (rhMenu);
                break;
            case 'auxiliar':
                menu = (auxMenu);
                break;
            case 'almacen':
                menu = (almacenMenu);
                break;
            // case 'junta_directiva':
            //     menu = (juntaMenu);
            //     break;
            case 'recursos_materiales':
                menu=(rmMenu);
                case 'servidor_publico':
                menu=(SPMenu);
                break;

                case 'recursos_financieros':
                menu=(rfMenu);
                break;
                
        }
        /*const options = menu.reduce((acc, currenItem, index) => {
                if(currenItem.hasOwnProperty('children')){
                    const moreOptions = currenItem.children.map(option => {
                        return {
                            id:   option.id,
                            name: option.title, 
                            path: option.url, 
                            icon: option.icon
                        }
                    })
                    currenItem.options = [...moreOptions]
                    return [...acc, ...moreOptions]
                }else {
                    return [...acc,{
                        id:   currenItem.id,
                        name: currenItem.title, 
                        path: currenItem.url, 
                        icon: currenItem.icon
                    }] 
                }
        }, []);
        */ const options = menu.map(currenItem => {
            let childrenOptions = [];
            if(currenItem.hasOwnProperty('children')){
                const moreOptions = currenItem.children.map(option => {
                    return {
                        id:   option.id,
                        name: option.title, 
                        path: option.url, 
                        icon: option.icon
                    }
                });
                childrenOptions = [...moreOptions];
            }
            return {
                id:   currenItem.id,
                name: currenItem.title, 
                path: currenItem.url, 
                icon: currenItem.icon,
                boards: childrenOptions
            }
        })
        options.splice(0, 1);
        return options;
    }
}
