<!-- BOARDS -->
<div id="boards" class="fuse-white" fxLayout="column" fxLayoutAlign="start center" fxFlex fusePerfectScrollbar>

  <div class="header pt-24 pt-md-2" fxLayout="row" fxLayoutAlign="center center" 
       [@animate]="{value:'*',params:{delay:'100ms'}}">
      <!--<div class="logo-tri" [@animate]="{value:'*',params:{scale:'0.5'}}">
          <img src="assets/images/logos/tr.png">
      </div>-->

      <div class="logo" [@animate]="{value:'*',params:{scale:'0.2'}}">
          <img width="250" src="assets/images/logos/tr.png">
      </div>
  </div>

  <!-- BOARD LIST -->
  <section  fxFlex="0 0 auto"  fxLayoutAlign="center start">
    
  <div *ngFor="let option of boards" class="board-list" fxFlex="0 0 auto" fxLayout="column wrap" fxLayoutAlign="center center"
      [@animateStagger]="{value:'50'}" >
      <!--Board title-->
      <div class="board-list-item board-title {{option.id}}"
          fxLayout="column" fxLayoutAlign="center center"
          [@animate]="{value:'*',params:{y:'100%'}}">
        <mat-icon class="s-56">{{option.icon}}</mat-icon>
        <div class="board-name">{{option.name}}</div>
        <mat-icon class="s-56">keyboard_arrow_down</mat-icon>

    </div>
    <!--<div class="verticalLine">-->
      <!-- BOARD -->
      <div class="board-list-item board-item {{board.id}}" *ngFor="let board of option.boards"
           [routerLink]="[board.path]" routerLinkActive="router-link-active" 
           fxLayout="column" fxLayoutAlign="center center"
           [@animate]="{value:'*',params:{y:'100%'}}">
          <mat-icon class="s-48">{{board.icon}}</mat-icon>
          <div class="board-name">{{board.name}}</div>
      </div>
      <!-- / BOARD -->
      </div>

 <!-- </div>-->
  <!-- / BOARD LIST -->
  </section>
</div>
<!-- / BOARDS -->
