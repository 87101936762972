import { FuseNavigation } from '@fuse/types';



const dashboardMenu: FuseNavigation = {
    id       : 'applications',
    title    : 'Inicio',
    translate: 'NAV.APPLICATIONS',
    type     : 'group',
    icon     : 'home',
    url      : '/inicio',
   
};

const usersPromoventes: FuseNavigation = {
    id      : 'users-promoventes',
    title   : 'Usuarios Promoventes',
    type    : 'item',
    icon    : 'accessibility_new',
    url  : '/users/list/ciudadano',
    children: [
        {
            id   : 'create-promovente',
            title: 'Crear usuario',
            type : 'item',
            url  : '/users/promotors-create'
        },
        {
            id   : 'list-promovente',
            title: 'Listar usuario',
            type : 'item',
            url  : '/users/list/ciudadano'
        }
    ]
};

const userOfficers: FuseNavigation = {
    id      : 'officers',
    title   : 'Gestión de empleados',
    type    : 'item',
    icon    : 'supervisor_account',
    url  : '/settings/list/empleado',
    // children: [
    //     {
    //         id   : 'create-promovente',
    //         title: 'Crear usuario',
    //         type : 'item',
    //         url  : '/users/officers-create'
    //     },
    //     {
    //         id   : 'list-promovente',
    //         title: 'Listar usuario',
    //         type : 'item',
    //         url  : '/users/list/empleado'
    //     }
    // ]
};

const depreciation: FuseNavigation = {
    id      : 'officers',
    title   : 'Gestión de depreciación',
    type    : 'item',
    icon    : 'multiline_chart',
    url  : '/settings/depreciation',
}


const verification_manager: FuseNavigation = {
    id      : 'verification_manager',
    title   : 'Periodos de verificación',
    type    : 'item',
    icon    : 'multiline_chart',
    url  : '/verification-manager'
}


const calendar: FuseNavigation = {
    id      : 'calendar',
    title   : 'Calendario',
    type    : 'item',
    icon    : 'calendar_today',
    url  : '/apps/calendar'
}

const almacen: FuseNavigation = {
    id: 'warehouse',
    title: 'Almacenes',
    type: 'item',
    icon: 'save',
    url : '/settings/almacenes'
}

const department: FuseNavigation = {
    id: 'department',
    title: 'Unidades administrativas',
    type: 'item',
    icon: 'business',
    url : '/settings/departamentos'
}

const active: FuseNavigation = {
    id: 'active',
    title: 'Activos',
    type: 'item',
    icon: 'spellcheck',
    url: '/settings/activos'
}

const catalogue: FuseNavigation = {
    id: 'active',
    title: 'Catálogo',
    type: 'item',
    icon: 'filter_none',
    url: '/settings/catalogo'
}

const groupeactivie: FuseNavigation = {
    id: 'groupeactivie',
    title: 'Subactivos',
    type: 'item',
    icon: 'layers',
    url: '/settings/grupo_activos'
}

const article: FuseNavigation = {
    id: 'article',
    title: 'Articulos',
    type: 'item',
    icon: 'add_shopping_cart',
    url: '/settings/articulos'
}

const invoice: FuseNavigation = {
    id: 'invoice',
    title: 'Facturas',
    type: 'item',
    icon: 'description',
    url: '/settings/facturas'
}

const providers: FuseNavigation = {
    id: 'providers',
    title: 'Proveedores',
    type: 'item',
    icon: 'local_shipping',
    url: '/settings/proveedores'
}

/*const usersReceptionMenu: FuseNavigation = {
    id: 'users_reception',
    title: 'Usuarios Recepción',
    type: 'item',
    icon: 'directions_walk',
    url: '/settings/usuarios/recepcion'
}*/


const usersMenu: FuseNavigation = {
    id      : 'users',
    title   : 'Administración',
    type    : 'collapsable',
    icon    : 'settings',
    children: [
        // usersPromoventes,
        userOfficers
    ]
}

const almacenistaMenu: FuseNavigation = {
    id      : 'users',
    title   : 'Administración',
    type    : 'collapsable',
    icon    : 'settings',
    children: [
        calendar,
    ]
}

const administationMenu: FuseNavigation = {
    id      : 'users',
    title   : 'Administración',
    type    : 'collapsable',
    icon    : 'settings',
    children: [
        verification_manager,
        calendar,
        depreciation,
        userOfficers,
        almacen,
        department,
        catalogue,
        active,
        groupeactivie,
        article,
        invoice,
        providers,
        //usersReceptionMenu
    ]
}

const calendarMenu: FuseNavigation = {
    id      : 'calendar',
    title   : 'Calendario',
    type    : 'collapsable',
    icon    : 'calendar_today',
    children: [
        calendar
    ]
}

const expedientMenu: FuseNavigation = {
    id      : 'manager-expediente',
    title   : 'Bienes',
    type    : 'collapsable',
    icon    : 'list_alt',
    children: [
        {
            id   : 'create-expediente',
            title: 'Registro de un Bien Mueble',
            type : 'item',
            icon : 'edit',
            url  : '/management/expedient-create'
        },
        {
            id   : 'mov-servidores',
            title: 'Búsqueda y Reportes',
            type : 'item',
            icon : 'search',
            url  : '/searching/tag/servidores'
        },
        {
            id : 'barras-menu',
            title: 'Código de Barras',
            type     : 'item',
            icon : 'spellcheck',
            url  : '/barcode/generar',
        },
        {
            id   : 'mov-edit',
            title: 'Solicitar modificación',
            type : 'item',
            icon : 'domain_disabled',
            url  : '/notifications-property/mis-solicitudes'
        },
        {
            id   : 'mov-aprov',
            title: 'Bienes sin asignar',
            type : 'item',
            icon : 'check_circle',
            url  : '/management/expedients'
        }
    ]
}

const requestMenu: FuseNavigation = {
    id: 'manager-request',
    title: 'Solicitudes',
    type: 'collapsable',
    icon: 'important_devices',
    children: [
        {
            id   : 'mov-aprov',
            title: 'Modificaciones',
            type : 'item',
            icon : 'check_circle',
            url  : '/notifications-property/solicitudes'
        },
        {
            id   : 'mov-aprov',
            title: 'Bajas',
            type : 'item',
            icon : 'check_circle',
            url  : '/notifications-property/solicitudes-baja'
        },
    ]
}

const requestMenuUsers: FuseNavigation={
    id: 'manager-request',
    title: 'Solicitudes',
    type: 'collapsable',
    icon: 'devices',
    children: [
        {
            id   : 'mov-aprov',
            title: 'Bajas',
            type : 'item',
            icon : 'check_circle',
            url  : '/notifications-property/solicitudes-baja'
        },
    ]
}
const auxiMenu: FuseNavigation = {
    id      : 'manager-expediente',
    title   : 'Bienes',
    type    : 'collapsable',
    icon    : 'important_devices',
    children: [
        {
            id   : 'create-expediente',
            title: 'Registro de un Bien Mueble',
            type : 'item',
            icon : 'edit',
            url  : '/management/expedient-create'
        },
        {
            id   : 'mov-baja',
            title: 'Solicitudes',
            type : 'item',
            icon : 'domain_disabled',
            url  : '/notifications-property/mis-solicitudes'
        },
    ]
}

const juntagMenu: FuseNavigation = {
    id      : 'manager-expediente',
    title   : 'Bienes',
    type    : 'collapsable',
    icon    : 'important_devices',
    children: [
        {
            id   : 'mov-aprov',
            title: 'Solicitudes por aprobar',
            type : 'item',
            icon : 'check_circle',
            url  : '/notifications-property/solicitudes'
        },
    ]
}

const movementsMenu: FuseNavigation = {
        
    id      : 'admin-movimientos',
    title   : 'Movimientos',
    type    : 'collapsable',
    icon    : 'import_export',
    children: [
        /*{
            id   : 'mov-asignacion',
            title: 'Asignación',
            type : 'item',
            icon : 'how_to_reg',
            url  : '/movement/create/asignacion'
        },*/
        {
            id   : 'mov-servidores',
            title: 'Transferencia de bienes',
            type : 'item',
            icon : 'supervised_user_circle',
            url  : '/movement/create/servidores'
        },
        {
            id   : 'mov-baja',
            title: 'Solicitud Baja',
            type : 'item',
            icon : 'transit_enterexit',
            url  : '/movement/create/baja'
        },
        {
            id   : 'mov-entrega',
            title: 'Regresar a stock',
            type : 'item',
            icon : 'transfer_within_a_station',
            url  : '/movement/create/entrega'
        },
        {
            id   : 'mov-recepcion',
            title: 'Entrega-recepción',
            type : 'item',
            icon : 'compare_arrows',
            url  : '/movement/create/recepcion'
        },
        {
            id : 'mov-menu',
            title: 'Lista de movimientos',
            type: 'item',
            icon: 'list_alt',
            url: '/movement/movimientos',
        },
    ]
}

const searchMenu: FuseNavigation = {
    id      : 'search',
    title   : 'Búsqueda y Reportes',
    type    : 'collapsable',
    icon    : 'search',
    children: [
        {
            id   : 'mov-servidores',
            title: 'Búsqueda',
            type : 'item',
            icon : 'supervised_user_circle',
            url  : '/searching/tag/servidores'
        },
        /*{
            id   : 'mov-unidad',
            title: 'Unidad administrativa',
            type : 'item',
            icon : 'business',
            url  : '/searching/tag/unidad'
        },
        {
            id   : 'mov-asignacion',
            title: 'Asignación',
            type : 'item',
            icon : 'playlist_add_check',
            url  : '/searching/filter/asignado'
        },
        {
            id   : 'mov-reasignacion',
            title: 'Reasignación',
            type : 'item',
            icon : 'cached',
            url  : '/searching/filter/reasignado'
        },
        {
            id : 'mov-solicitudes',
            title: 'Solicitudes modificación/baja',
            type: 'item',
            icon: 'list_alt',
            url: '/notifications-property/mis-solicitudes',
        },
        {
            id   : 'mov-baja',
            title: 'Solicitar modificación/baja',
            type : 'item',
            icon : 'domain_disabled',
            url  : '/notifications-property/mis-solicitudes'
        },
        {
            id   : 'mov-aprov',
            title: 'Solicitudes por aprovar',
            type : 'item',
            icon : 'check_circle',
            url  : '/notifications-property/solicitudes'
        }*/
    ]
}

const reportsMenu: FuseNavigation = {
    id      : 'search',
    title   : 'Reportes',
    type    : 'collapsable',
    icon    : 'format_list_bulleted',
    children: [
        {
            id   : 'rep-bienes',
            title: 'Inventario Bienes Inmuebles',
            type : 'item',
            icon : 'important_devices',
            url  : '/notification'
        },
        {
            id   : 'rep-adquiridos',
            title: 'Bienes Muebles adquiridos',
            type : 'item',
            icon : 'trending_up',
            url  : '/notification'
        },
        {
            id   : 'rep-estado',
            title: 'Bienes Muebles por estado de uso',
            type : 'item',
            icon : 'update',
            url  : '/notification/list'
        },
    ]
}

export const adminMenu: FuseNavigation[] = [
    dashboardMenu,
   //expedientAdminMenu,
    //notificationMenu,
    usersMenu,
    //authoritiesMenu,
];

export const streamMenu: FuseNavigation[] = [
    dashboardMenu,
    expedientMenu,
    movementsMenu,
    requestMenu,
    //searchMenu,
    //calendarMenu,
    administationMenu,

    
];

export const rhMenu: FuseNavigation[] = [
    dashboardMenu,
    usersMenu,
];

export const almacenMenu: FuseNavigation[] = [
    dashboardMenu,
    almacenistaMenu,
];

export const auxMenu: FuseNavigation[] = [
    dashboardMenu,
    auxiMenu,
];

export const juntaMenu: FuseNavigation[] = [
    dashboardMenu,
    juntagMenu,
];

export const rmMenu: FuseNavigation[] = [
    dashboardMenu,
    expedientMenu,
    movementsMenu,
    requestMenuUsers


];
export const SPMenu: FuseNavigation[] = [
    dashboardMenu,
    expedientMenu,
    movementsMenu,
    requestMenuUsers


];
const DepreMenu: FuseNavigation = {
    id      : 'officers',
    title   : 'Administración',
    type    : 'group',
    icon    : 'settings',
   
    children: [
        {
            id      : 'officers',
            title   : 'Gestión de depreciación',
            type    : 'item',
            icon    : 'multiline_chart',
            url  : '/settings/depreciation',
        }
    ]
}
export const rfMenu: FuseNavigation[] = [
    dashboardMenu,
    DepreMenu,
    

];

/**
 * 
 * recursos materiales completo
 * 
 * auxiliar -> baja
 * 
 * almacen -> calendario y detalle
 * 
 * administrativo -> calendario
 */